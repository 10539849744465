html {
  background: #eeeeee;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}


/* Wysiwyg CSS */

.rdw-option-active {
  background-color: #DCE1EA !important;
  box-shadow: none;
}
.rdw-option-wrapper {
  background-color: #f8fafd;
}
.rdw-option-wrapper:hover {
  box-shadow: none;
  background-color: #DCE1EA;
}

.rdw-dropdown-wrapper:hover {
  background-color: #DCE1EA;
  box-shadow: none;
}
.rdw-dropdown-wrapper {
  background-color: #f8fafd;
}